<template>
  <div class="readme-article">
    <h1 id="财务支出">
      二、财务支出
    </h1>
    <h2 id="日常支出流水">1.日常支出流水</h2>
    <p>查看日常支出流水列表，包含支出项目、金额等信息，支持条件查询。</p>
    <p>
      <img src="@/assets/img/finance/2-1.png">
    </p>
    <h2 id="添加财务支出">2.添加财务支出</h2>
    <p>管理员可添加财务支出，需要填写单据编号、支付渠道、支出类型、金额信息。</p>
    <p>
      <img src="@/assets/img/finance/2-2.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Finance2-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>